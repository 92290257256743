import { Component, OnInit } from '@angular/core';
import { SubscriberBaseComponent } from '../../shared/components/base/subscriber-base/subscriber-base.component';
import { BasicCardData } from '../../shared/models/model';
const me: String = "assets/sexy_dave.png" // me

@Component({
    selector: 'dashboard-container',
    templateUrl: './dashboard-container.component.html',
    styleUrls: ['./dashboard-container.component.scss']
})
export class DashboardContainerComponent extends SubscriberBaseComponent implements OnInit {

    cardDatas: BasicCardData[] = [
        {
            headerImage: me,
            headerTitle: "Great things incoming!",
            subTitle: "Greatness comes to those who don't wait",
            cardMainImage: "https://upload.wikimedia.org/wikipedia/commons/a/a9/GrizzlyBearJeanBeaufort.jpg",
            cardMainImageAltText: "image of grizzly bear",
            cardTextBody: `nothing works yet but it might soon`
        }
    ]

    constructor() {
        super()
    }

    ngOnInit() {
    }
}
